@use "../utils/colors" as *;
@use "../utils/theme" as *;
@use "../utils/fonts.scss" as *;

.container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    border-bottom: 2px solid rgba(65, 90, 119, .2);
    padding: 0px 20px;
    width: 100%;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    padding: 10px;
    width: 30px;
    height: 30px;
    font-size: 22px;
    // background-color: $primary_color_light;
    color: $primary_color_dark;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.title {
    font-size: 12px;
    color: $font-family-primary;
    font-family: $font-family-secondary;
}

.username {
    font-size: 18px;
    color: $font-family-primary;
    font-family: $font-family-primary;
}