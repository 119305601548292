@use "../utils/colors" as *;
@use "../utils/theme" as *;
@use "../utils/fonts.scss" as *;

.content {
    font-family: $font-family-primary;
    color: $primary_color_light;
    text-align: start;
    width: 100%;
    list-style-type: disc;
    height: 100%;
}

.subtitle {
    font-size: 16px;
    text-decoration: underline;
    font-weight: bold;
    list-style: none;
}

.main {
    overflow: hidden;
    margin: 10px 30px;
    padding: 30px 50px;
}

.title {
    font-size: 36px;
    font-family: $font-family-primary;
    text-align: center;
    padding-bottom: 20px;
}