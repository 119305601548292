@use "../utils/colors" as *;
@use "../../scss/utils/fonts.scss" as *;
.about {
  height: 90vh;
  background: url(../../assests/images//bout_background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  color: $text_color_dark;
}
.content {
  background-color: $primary_color_light;
  opacity: 0.85;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 3rem;
}
.title {
  font-size: 36px;
  font-family: $font-family-primary;
}
.text {
  font-size: 20px;
  width: 60%;
  word-spacing: 0.5rem;
  display: flex;
  gap: 30px;
  flex-direction: column;
  color: $text_color_dark;
  font-family: $font-family-primary;
  font-weight: 400;
}
