@use "../utils/colors" as *;
@use "../utils/theme" as *;
@use "../utils/fonts.scss" as *;

.design_main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;
  padding: 5rem 10rem;
  text-wrap: wrap;
  // height: 80vh;
}
.left {
  color: var(--secondary_color);
  font-family: $font-family-primary;
  display: flex;
  width: 60%;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
}
.title {
  font-size: 36px;
}
.slogan {
  font-size: 64px;
  font-family: $font-family-secondary;
}
.right {
  width: 60%;
}
.anim {
  width: 100%;
  height: 100%;
}
.context {
  font-size: 18px;
  word-wrap: break-word;
  word-spacing: 10px;
  line-height: 1.8rem;
  font-family: $font-family-secondary;
}
