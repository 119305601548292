@use "../utils/colors" as *;
@use "../utils/theme" as *;
@use "../utils/fonts.scss" as *;

.main {
  display: flex;
  background-color: $primary_color_dark;
  color: $text_color_light;
  font-family: $font-family-primary;
  justify-content: space-evenly;
  align-items: start;
  padding: 20px 0px;
  font-weight: 300;
  font-size: 14px;

}
ul {
  list-style-type: none;
}
ul li {
  padding-bottom: 15px;
}
.name {
  font-size: 20px;
}
.title {
  text-decoration: underline;
  font-weight: bold;
}
.contact {
  text-align: center;
}
.address {
  text-align: center;
}

.logo {
  height: 6rem;
}