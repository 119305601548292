$primary_color_dark: #0d1b2a;
$primary_color_light: #f4faff;

$black: #000;
$white: #fff;

$secondary_color_light: #f4faff;
$secondary_color_dark: #0d1b2a;

$third_color: #accbe1;

$text_color_dark: $primary_color_dark;
$text_color_light: $primary_color_light;

$hover_color: #415a77;
