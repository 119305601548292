@use "../utils/colors" as *;
@use "../utils/fonts" as *;

html[data-theme="dark"] {
  --background-color: #{$primary_color_dark};
  --color: #{$text_color_light};

  --primary_color: #{$primary_color_dark};
  --secondary_color: #{$secondary_color_light};

  --font-family: $font-family-primary;
}

html[data-theme="light"] {
  --background-color: #{$primary_color_light};
  --color: #{$text_color_dark};

  --primary_color: #{$primary_color_light};
  --secondary_color: #{$secondary_color_dark};

  --font-family: $font-family-primary;
}
