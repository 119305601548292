@use "../utils/theme" as *;
@use "../utils//fonts.scss" as *;

.navbar {
  color: var(--color);
  font-family: $font-family-primary;
  font-weight: 300;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px;
  background-color: var(--primary_color);
  position: fixed;
  width: 95vw;
  z-index: 1;
}

.logo {
  cursor: pointer;
}
.logo img {
  height: 4rem;
  padding: 10px 10px;

}

.items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50%;
}
.nav-items {
  display: flex;
  flex-direction: row;
  gap: 40px;
  list-style: none;
  border-radius: 30px;
  background-color: var(--color);
  padding: 10px 30px;
  color: var(--primary_color);
}
.nav-items li {
  font-size: 14px;
  transition: all 200ms ease;
  padding: 6px 15px;
  border-radius: 30px;
  cursor: pointer;
}

.nav-items li:hover {
  background-color: var(--primary_color);
  color: var(--color);
}
.theme-toggle {
  padding: 5px;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.theme-toggle {
  background-color: var(--primary_color);
  transition: background-color 300ms ease;
}
.theme-toggle:hover {
  background-color: var(--secondary_color);
  color: var(--primary_color);
}
